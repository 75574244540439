footer {
  background:$green;
  width:100%;
  float:left;
  padding:35px 0;
  color:white;
  h4 {
    font-size:25px;
    font-family: $font-standard;
    font-weight:900;
    margin:0 0 10px 0;
    padding:0;
    color:white;
    text-transform: uppercase;
  }
  p {
    line-height:30px;
    margin:0;
  }
  a {
    color:white;
  }
  i {
    margin-right:4px;
  }
  .footer-links {
    margin:0;
    padding:0;
    list-style:none;
    li {
      width:50%;
      float:left;
      padding:4px 0;
      a {
        position:relative;
        padding-left:18px;
        &:before {
          content:"";
          width:10px;
          height:1px;
          background:white;
          position:absolute;
          left:0;
          top:11px;
        }
        &:hover,
        &:focus {
          text-decoration: none;
          color:$pink;
        }
      }
    }
  }
  .footer-icons {
    margin:25px auto 10px auto;
    padding:0;
    list-style:none;
    display:block;
    li {
      display:inline-block;
      a {
        display:block;
        width:60px;
        height:60px;
        margin:0 2px 0 2px;
        position:relative;
        background:white;
        border-radius:50%;
        img {
          max-width:40px;
          position:absolute;
          left:50%;
          top:50%;
          transform:translate(-50%,-50%);
        }
      }
    }
  }
  .copy {
    font-size:13px;
  }
}