.btn-primary {
  background: rgb(162,192,55);
  background: -moz-linear-gradient(45deg, rgba(171,11,107,1) 1%, rgba(196,15,123,1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(171,11,107,1) 1%,rgba(196,15,123,1) 100%);
  background: linear-gradient(45deg, rgba(171,11,107,1) 1%,rgba(196,15,123,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ab0b6b', endColorstr='#c40f7b',GradientType=1 );
  color:white !important;
  padding:12px 24px;
  border-radius:30px;
  border:0;
  font-size:18px;
  font-weight:900;
  font-family: $font-standard;
  text-transform: uppercase;
  &:hover,
  &:focus {
    background:$green;
  }
}

.btn-slide {
  color:white;
  border:3px solid white;
  padding:15px 25px;
  border-radius:50px;
  text-align:left;
  font-size:20px;
  font-weight:900;
  font-family: $font-standard;
  text-transform:uppercase;
  position:absolute;
  bottom:0;
  left:0;
  right:60px;
  margin:0 auto;
  display:inline-block;
  max-width:275px;
  &:after {
    content:"\f054";
    position:absolute;
    font-family: FontAwesome;
    right:25px;
    top:18px;
    font-size:18px;
  }
  &:hover,
  &:focus {
    background:white;
    color:$pink;
    text-decoration: none;
  }
}
.btn-register {
  width:100%;
  border:1px solid #efefef;
  float:left;
  position:relative;
  padding:25px;
  display: block;
  overflow: hidden;
  background:white;
  margin-top:25px;
  span.icon-container {
    float:left;
    display:block;
    border-radius:50%;
    overflow:hidden;
    margin:0 25px 0 0;
    width:120px;
    height:120px;
    padding:3px;
    background: rgb(162,192,55);
    background: -moz-linear-gradient(45deg, rgba(162,192,55,1) 1%, rgba(196,15,123,1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(162,192,55,1) 1%,rgba(196,15,123,1) 100%);
    background: linear-gradient(45deg, rgba(162,192,55,1) 1%,rgba(196,15,123,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a2c037', endColorstr='#c40f7b',GradientType=1 );
    .icon {
      width:100%;
      height:100%;
      position:relative;
      display:block;
      border-radius:50%;
      background:white;
    }
    img {
      background:white;
      border-radius:0;
      position:absolute;
      top:50%;
      left:50%;
      transform:translate(-50%,-50%);
    }
  }
  span.btn-text {
    display:block;
    overflow:hidden;
    color:$pink;
    margin:10px 0 0 0;
    font-size:36px;
    line-height:48px;
    text-transform:uppercase;
    font-weight:900;
  }
  &:before {
    content:"\f054";
    font-family: FontAwesome;
    position:absolute;
    right:10px;
    bottom:8px;
    color:white;
    font-size:16px;
    z-index:11;
    transition:ease all 0.25s;
  }
  &:after {
    content:"";
    width:90px;
    height:90px;
    background:$pink;
    transform:rotate(-45deg);
    position:absolute;
    bottom:-45px;
    right:-45px;
    z-index:10;
    transition:ease all 0.25s;
  }
  &:hover,
  &:focus {
    text-decoration: none;
    background:rgba(255,255,255,0.6);
    box-shadow:0 1px 2px rgba(0,0,0,0.05);
    &:before {
      font-size:21px;
      right:10px;
      bottom:10px;
    }
    &:after {
      width:110px;
      height:110px;
      bottom:-55px;
      right:-55px;
    }
  }
}