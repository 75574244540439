#home-introduction {
  background:#fafafa;
  position:relative;
  &:after {
    content:"";
    width:100%;
    height:100%;
    position:absolute;
    bottom:0;
    z-index:1;
    background:url(/images/bg.jpg) no-repeat;
    opacity:0.75;
    background-attachment: fixed;
  }
  .home-intro-block {
    text-align:center;
    position:relative;
    z-index:10;
    h1 {
      color:$green;
      font-family: $font-standard;
      text-transform: uppercase;
      font-size:40px;
      font-weight:900;
      margin:0 0 30px 0;
      padding:0 0 54px 0;
      position:relative;
      &:after {
        content:"";
        position:absolute;
        left:0;
        right:0;
        bottom:0;
        margin:0 auto;
        width:87px;
        height:24px;
        background:url('/images/icons/title-tooth.png') no-repeat;
      }
    }
    p {
      font-size:18px;
      font-weight:400;
      font-family: $font-standard;
      line-height:36px;
      margin:0;
      padding:0 40px;
      color:$grey;
    }
  }
  .row-services {
    padding-top:90px;
    position:relative;
    z-index:10;
  }
  .service {
    float:left;
    width:100%;
    border:1px solid #dedede;
    background:white;
    padding:0 0 50px 0;
    margin:0 0 30px 0;
    display:block;
    overflow:hidden;
    position:relative;
    transition:ease all 0.25s;
    span.icon-container {
      display:block;
      border-radius:50%;
      overflow:hidden;
      margin:32px auto;
      width:120px;
      height:120px;
      padding:3px;
      background: rgb(162,192,55);
      background: -moz-linear-gradient(45deg, rgba(162,192,55,1) 1%, rgba(196,15,123,1) 100%);
      background: -webkit-linear-gradient(45deg, rgba(162,192,55,1) 1%,rgba(196,15,123,1) 100%);
      background: linear-gradient(45deg, rgba(162,192,55,1) 1%,rgba(196,15,123,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a2c037', endColorstr='#c40f7b',GradientType=1 );
      .icon {
        width:100%;
        height:100%;
        position:relative;
        display:block;
        border-radius:50%;
        background:white;
      }
      img {
        background:white;
        border-radius:0;
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
      }
    }
    span.title {
      display:block;
      text-align:center;
      color:$grey;
      font-family: $font-standard;
      font-size:21px;
      font-weight:900;
      text-transform:uppercase;
      position:relative;
      padding:0 0 15px 0;
      margin:0 0 15px 0;
      &:after {
        content:"";
        width:50px;
        height:1px;
        background:$pink;
        position:absolute;
        left:0;
        right:0;
        bottom:0;
        margin:0 auto;
      }
    }
    span.short {
      display:block;
      color:$grey;
      font-size:16px;
      font-weight:300;
      font-family: $font-standard;
      position:relative;
      line-height:28px;
      text-align:center;
      padding:0 25px;
      height:130px;
      overflow:hidden;
    }
    &:before {
      content:"\f054";
      font-family: FontAwesome;
      position:absolute;
      right:10px;
      bottom:8px;
      color:white;
      font-size:16px;
      z-index:11;
      transition:ease all 0.25s;
    }
    &:after {
      content:"";
      width:90px;
      height:90px;
      background:$pink;
      transform:rotate(-45deg);
      position:absolute;
      bottom:-45px;
      right:-45px;
      z-index:10;
      transition:ease all 0.25s;
    }
    &:hover,
    &:focus {
      text-decoration: none;
      background:rgba(255,255,255,0.6);
      box-shadow:0 2px 4px rgba(0,0,0,0.15);
      &:before {
        font-size:21px;
        right:10px;
        bottom:10px;
      }
      &:after {
        width:110px;
        height:110px;
        bottom:-55px;
        right:-55px;
      }
    }
  }
  .row-services .col-md-4:last-child .service {
    background:$pink;
    border:1px solid $pink;
    color:white;
    span.icon-container {
      background:transparent !important;
      border:3px solid white;
      .icon {
        background:transparent;
        img {
          background:transparent;
          border-radius:0;
        }
      }
    }
    span.title {
      color:white;
      &:after {
        background:white;
      }
    }
    span.short {
      color:white;
    }
  }
}
#slider-reference {
  .owl-dots {
    position:absolute;
    top:60px;
    right:0;
    width:50%;
    text-align:center;
    .owl-dot {
      width:30px;
      height:29px;
      background:url(/images/tooth-dots.png) no-repeat 0 0;
      position:relative;
      display:inline-block;
      margin:0 0 0 4px;
      &.active {
        background:url(/images/tooth-dots.png) no-repeat -30px 0;
      }
    }
  }
}
