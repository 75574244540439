header {
  width:100%;
  position:relative;
  float:left;
  padding-bottom:40px;
  padding-top:5px;
  height:115px;
  z-index:1001;
  .brand {
    float:left;
    width:350px;
    margin-top:-15px;
    img {
      max-width:100%;
    }
  }
  .contact-info {
    float:right;
    margin-top:24px;
    .more-info-title {
      float:right;
      display:block;
      text-align:right;
      font-family: $font-standard;
      font-size:18px;
      color:$grey;
    }
    ul {
      margin:5px 0 0 0;
      padding:0;
      list-style:none;
      li {
        float:left;
        &:first-child {
          margin-right:60px;
          a {
            &:before {
              content:"\f003";
              font-family: FontAwesome;
              position:absolute;
              left:0;
              top:-1px;
              color:$grey;
            }
          }
        }
        &:last-child {
          a {
            padding-left:35px;
            &:before {
              content:"\f095";
              font-family: FontAwesome;
              position:absolute;
              left:0;
              top:1px;
              color:$grey;
            }
          }
        }
        a {
          color:$grey;
          font-size:21px;
          font-weight:700;
          position:relative;
          padding-left:35px;
          &:hover,
          &:focus {
            color:$green;
            text-decoration: none;
            &:before {
              color:$green;
            }
          }
        }
      }
    }
  }
}