#treatments {
  background:#fafafa;
  .text-block {
    padding:90px 0;
    min-height:600px;
  }
  .treatment {
    float:left;
    width:100%;
    position:relative;
    margin-bottom:15px;
    h2 {
      margin:0 0 10px 0;
      padding:0;
      color:$green;
      font-size:25px;
      text-transform: uppercase;
      font-weight:900;
      position:relative;
    }
  }
  .treatment-items {
    h2 {
      padding:0 0 0 60px;
    }
    .img-icon {
      position:absolute;
      left:0;
      top:-18px;
    }
  }
}