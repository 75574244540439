@media only screen and (min-width : 992px) {
  #sidebar-filter.affix-top {
    position: static;
    width: 262px;
  }
  #sidebar-filter.affix-bottom {
    position: absolute;
    width: 262px;
  }

  #sidebar-filter.affix {
    position: fixed;
    top: 72px;
    width: 262px;
  }
}
@media only screen and (max-width: 991px) {
  section {
    &.padded {
      padding:80px 45px;
    }
  }
  header {
    .contact-info {
      ul {
        li {
          a {
            padding-left:30px;
            font-size:18px;
          }
          &:first-child {
            margin-right:25px;
          }
          &:last-child {
            a {
              padding-left:25px;
            }
          }
        }
      }
    }
  }
  .navbar-fixed-top {
    #navbar {
      .navbar-nav {
        li {
          a {
            font-size:15px;
            padding:18px 9px;
          }
          &:first-child {
            border-left:0;
          }
          &:last-child {
            border-right:0;
          }
        }
      }
    }
  }

  #slider-container {
    #slider {
      .owl-slide {
        .block-slide {
          margin-left:108px;
        }
      }
    }
  }

  .page-heading {
    min-height:290px;
    height:290px;
  }
  .col-sidebar {
    display:none;
  }
  .col-text {
    width:100%;
  }
  #treatments .text-block {
    padding:60px 45px;
  }

  #contact-info {
    text-align:center;
  }
  .table-times {
    margin:40px auto 0 auto;
  }
  footer {
    .col-md-6 {
      &:first-child {
        text-align:center;
      }
    }
    p {
      font-size:20px;
      line-height:35px;
    }
  }
}