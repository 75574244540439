#contact-info {
  p {
    line-height:32px;
    &.large {
      font-size:25px;
      line-height:40px;
      margin-top:30px;
      a {
        color:$green;
      }
    }
    .btn-primary {
      margin-top:10px;
    }
  }
  .address-info {
    h3 {
      margin-top:40px;
      font-size:21px;
      text-transform:inherit;
      font-weight:300;
      color:$grey;
    }
    p {
      line-height:32px;
    }
  }
}

.table-times {
  tr.current {
    td {
      color:$green;
    }
  }
}

#maps {
  float:left;
  width:100%;
  height:500px;
  overflow:hidden;
  position:relative;
}