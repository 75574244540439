html, body {
  height: 100%;
  background: white;
  padding: 0;
  margin: 0;
  font-size: 100%;
}
html {
  height: 100%;
}
body {
  height: auto !important;
  height: 100%;
  position: relative;
  min-height: 100%;
  overflow-x: hidden;
  font-family: $font-standard;
  font-weight: 300;
  font-style: normal;
  color:$grey;
  font-size: 100%;
  padding: 0;
  margin: 0;
}
a, button {
  outline:none;
  color:$green;
  &:hover,
  &:focus {
    outline:none;
  }
}
h2, h3, h4, h5, h6 {
  text-transform:uppercase;
  color:$green;
  font-weight:900;
  font-family: $font-standard;
}

section {
  float:left;
  width:100%;
  position:relative;
  &.padded {
    padding:80px 0;
  }
}
.page-heading {
  width:100%;
  min-height:320px;
  height:320px;
  margin-top:58px;
  float:left;
  position:relative;
  display:block;
  overflow:hidden;
  h1 {
    position:absolute;
    left:50%;
    top:50%;
    transform:translate(-50%,-50%);
    color:white;
    text-transform: uppercase;
    font-family: $font-standard;
    font-weight:900;
    font-size:60px;
    margin:0;
    padding:0;
    z-index:2;
  }
  #bg-image {
    height:350px;
  }
  &:after {
    content:"";
    width:100%;
    height:100%;
    opacity:0.6;
    position: absolute;
    bottom:0;
    left:0;
    z-index:1;
    background: rgb(162,192,55);
    background: -moz-linear-gradient(25deg, rgba(162,192,55,1) 1%, rgba(196,15,123,1) 100%);
    background: -webkit-linear-gradient(25deg, rgba(162,192,55,1) 1%,rgba(196,15,123,1) 100%);
    background: linear-gradient(25deg, rgba(162,192,55,1) 1%,rgba(196,15,123,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a2c037', endColorstr='#c40f7b',GradientType=1 );
  }
}

.default-page {
  background:#fafafa;
}
.text-block {
  p, ul, ol, blockquote {
    margin:0 0 36px 0;
    padding:0;
    font-family: $font-standard;
    font-size:18px;
    line-height:34px;
    color:$grey;
  }
  ul, ol {
    margin:0 0 36px 18px;
  }
}
.col-sidebar {
  .sidebar {
    border: 0;
    width: 100%;
    min-height: 100px;
    margin-top: 90px;
    padding:0;
    font-size: 16px;
    font-family: $font-standard;
    .nav-pills {
      li {
        padding:0;
        margin:0;
        a {
          background:transparent;
          color:$grey;
          font-weight:400;
          font-size:18px;
          font-family: $font-standard;
          border-bottom: 1px solid #eee;
          border-radius:0;
          &:hover,
          &:focus {
            border-radius:0;
            background:$green;
            color:white;
          }
        }
        &.active {
          a {
            border-radius:0;
            background:$green;
            color:white;
          }
        }
        &:last-child {
          a {
            border:0;
          }
        }
      }
    }
  }
}
.col-half {
  width:50%;
  float:left;
  position:relative;
  display:block;
  height:450px;
  .image-container {
    width:100%;
    height:100%;
    position:relative;
    display:block;
  }
  .text-container {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    width:100%;
    padding:90px 90px 30px 90px;
    text-align:center;
    h3 {
      font-size:24px;
      margin:0 0 20px 0;
      padding:0;
    }
    p {
      line-height:30px;
      margin:0;
      font-size:16px;
    }
    p.name {
      margin-top:15px;
      display:block;
    }
  }
}
.table-times {
  margin:40px 0 0 0;
  tr {
    td {
      padding:8px 0;
      border-bottom:1px solid $grey-light;
      &:first-child {
        text-transform:uppercase;
        font-family: $font-standard;
        font-weight:200;
        width:158px;
      }
      &:last-child {
        font-weight:700;
        color:$grey;
      }
    }
    &:last-child {
      td {
        border-bottom:0;
      }
    }
  }
}