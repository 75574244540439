#team {
  background:#fafafa;
}
.member {
  float:left;
  width:100%;
  border:1px solid #f4f4f4;
  background:white;
  padding:0 0 50px 0;
  margin:30px 0 0 0;
  display:block;
  overflow:hidden;
  position:relative;
  transition:ease all 0.25s;
  span.icon-container {
    display:block;
    border-radius:50%;
    overflow:hidden;
    margin:45px auto;
    width:200px;
    height:200px;
    padding:3px;
    background: rgb(162,192,55);
    background: -moz-linear-gradient(45deg, rgba(162,192,55,1) 1%, rgba(196,15,123,1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(162,192,55,1) 1%,rgba(196,15,123,1) 100%);
    background: linear-gradient(45deg, rgba(162,192,55,1) 1%,rgba(196,15,123,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a2c037', endColorstr='#c40f7b',GradientType=1 );
    .icon {
      width:100%;
      height:100%;
      position:relative;
      display:block;
      border-radius:50%;
      background:white;
    }
    img {
      background:white;
      border-radius:50%;
      position:absolute;
      top:50%;
      left:50%;
      transform:translate(-50%,-50%);
    }
  }
  span.title {
    display:block;
    text-align:center;
    font-family: $font-standard;
    font-size:24px;
    font-weight:900;
    text-transform:uppercase;
    position:relative;
    line-height:27px;
    color:$pink;
    .function {
      text-transform: capitalize;
      font-size:18px;
      font-weight:300;
      color:$green;
    }
  }
  span.short {
    display:block;
    color:lighten($grey, 25%);
    font-size:14px;
    font-weight:300;
    font-family: $font-standard;
    position:relative;
    line-height:32px;
    text-align:center;
    padding:0 25px 15px 25px;
    margin:0 0 15px 0;
    min-height:48px;
    overflow:hidden;
    &:after {
      content:"";
      width:50px;
      height:1px;
      background:$green;
      position:absolute;
      left:0;
      right:0;
      bottom:0;
      margin:0 auto;
    }
  }
}