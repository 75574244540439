#slider-container {
  width: 100%;
  height: 490px;
  margin-top: 58px;
  overflow: hidden;
  position: relative;
  float: left;
  display: block;
  #slider {
    width: 100%;
    height: 100%;
    min-height: 490px;
    position: relative;
    overflow: hidden;
    div:not(.owl-controls) {
      height: 100%;
      position: relative;
    }
    .owl-item {
      height: 100%;
      position: relative;
      &.active {

          .owl-slide {
            .block-slide {
              .title {
                h1,
                .btn-slide {
                  -webkit-animation-duration: 1s;
                  animation-duration: 1s;
                  -webkit-animation-fill-mode: both;
                  animation-fill-mode: both;
                  -webkit-animation-name: slideInUp;
                  animation-name: slideInUp;
                }
              }
            }

        }
      }
    }
    .owl-slide {
      z-index: 100;
      position: relative;
      .block-slide {
        position: relative;
        max-width: 500px;
        &:before {
          content: "";
          position: absolute;
          background: rgba(196, 15, 123, 0.7);
          transform: rotate(-15deg);
          width: 500px;
          height: 200%;
          top: -50%;
          left: -50px;
          z-index: 0;
        }
        .title {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: white;
          height: auto !important;
          width: 500px;
          padding: 0 120px 0 50px;
          h1 {
            width: 100%;
            margin: 0;
            padding: 0 0 95px 0;
            font-size: 28px;
            font-weight: 900;
            line-height: 38px;
            text-align: center;
            font-family: $font-standard;
            text-transform: uppercase;
          }
        }
      }
    }
    .owl-nav {
      position: absolute !important;
      width: 81px;
      height: 40px !important;
      bottom: 30px;
      right: 30px;
      .owl-prev,
      .owl-next {
        background: $green;
        width: 40px;
        height: 40px !important;
        font-size: 0;
        display: block;
        position: relative;
        float: left;
        opacity: 0.45;
        transition: opacity ease-in-out 0.3s;
        &:after {
          position: absolute;
          font-family: FontAwesome;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: white;
          font-size: 20px;
          display: block;
        }
        &:hover,
        &:focus {
          opacity: 1;
        }
      }
      .owl-next {
        margin-left: 1px;
        &:after {
          content: "\f054";
        }
      }
      .owl-prev {
        &:after {
          content: "\f053";
        }
      }
    }
  }
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}