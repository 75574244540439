@media only screen and (min-width: 768px) {
  .navbar-fixed-top {
    position: absolute;
    top: 150px;
    margin: 0;
    float: left;
    width: 100%;
    border-radius: 0;
    min-height: 56px;
    background: white;
    border-top: 1px solid $grey-light;
    border-bottom: 1px solid $grey-light;
    z-index:1001;
    #navbar {
      .navbar-nav {
        li {
          border-right: 1px solid $grey-light;
          a {
            padding: 18px 30px;
            height: 56px;
            font-weight: 500;
            font-size: 18px;
            text-transform: uppercase;
            color: $green;
            transition: background-color ease 0.25s;
            outline: none;
            &:hover,
            &:focus {
              background: $green;
              color: white;
            }
          }
          &.active {
            a {
              background: $green;
              color: white;
            }
          }
          &:first-child {
            border-left: 1px solid $grey-light;
          }
          &.dropdown {
            .dropdown-menu {
              padding: 0;
              li {
                border-left: 1px solid $grey-light;
                a {
                  font-size: 18px;
                  text-transform: inherit;
                  padding: 14px 20px;
                  height: auto;
                }
                &:last-child {
                  border-bottom: 1px solid $grey-light;
                }
              }
            }
            &:hover {
              .dropdown-menu {
                display: block;
              }
            }
          }
        }
      }
    }
    &.fixed {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  .menu-toggle {
    display: none;
  }
}