@media only screen and (min-width : 1200px) {
  #sidebar-filter.affix-top {
    width: 330px !important;
  }
  #sidebar-filter.affix-bottom {
    width: 330px !important;
  }

  #sidebar-filter.affix {
    width: 330px !important;
  }
}



@media only screen and (max-width: 1399px) {
  #slider-container {
    #slider {
      .owl-slide {
        .block-slide {
          margin-left:75px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1299px) {
  header {
    .brand {
      width:325px;
      img {
        width:325px !important;
        height:79px !important;
      }
    }
    .contact-info {
      margin-top:22px;
    }
  }
  #slider-container {
    margin-top:42px;
  }
  .navbar-fixed-top {
    top:150px;
  }

  .page-heading {
    margin-top:42px;
    h1 {
      width:100%;
      font-size:50px;
      text-align:center;
    }
  }

  .btn-register {
    span.btn-text {
      font-size:28px;
      line-height:36px;
      margin-top:22px;
    }
  }
}