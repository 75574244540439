@media only screen and (max-width: 1199px) {
  .container {
    width:100%;
  }
  header {
    .brand {
      margin-top:0;
    }
    .contact-info {
      ul {
        li {
          a {
            font-size:21px;
          }
        }
      }
    }
  }
  .navbar-fixed-top {
    #navbar {
      .navbar-nav {
        li {
          a {
            padding:19px 19px;
            font-size:18px;
          }
        }
      }
    }
  }
  #slider-container {
    height:400px;
    #slider {
      min-height:400px;
      .owl-slide {
        height:400px !important;
        .block-slide {
          .title {
            h1 {
              font-size:32px;
              line-height:42px;
            }
          }
        }
      }
    }
  }
  .col-sidebar {
    width:300px;
  }
  .col-half {
    .text-container {
      padding:70px 40px 30px 40px;
    }
  }

  .page-heading {
    h1 {
      font-size:40px;
    }
  }
}