@media only screen and (max-width: 767px) {
  body {
    padding-top:125px;
  }

  section {
    &.padded {
      padding:45px 15px;
    }
  }

  .navbar-top {
    position:fixed;
    background:#fafafa;
    top:0;
    .contact {
      width:100%;
      .route {
        float:right;
        margin-top:0;
      }
      .address {
        float:left;
        font-size:14px;
        margin-right:3px;
      }
    }
  }

  .navbar-fixed-top {
    position: fixed;
    top: 52px;
    background: transparent;
    width: 100%;
    right: 0;
    float: none;
    left: auto;
    border: 0;
    z-index:1001;
    button {
      background: transparent;
      border: 0;
      float: right;
      width: 45px;
      height: 35px;
      position: relative;
      margin: 14px 25px 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
      cursor: pointer;
      span {
        display: block;
        position: absolute;
        height: 4px;
        border-radius: 4px;
        width: 100%;
        background: $green;
        opacity: 1;
        left: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .25s ease-in-out;
        -moz-transition: .25s ease-in-out;
        -o-transition: .25s ease-in-out;
        transition: .25s ease-in-out;
      }
      span:nth-child(1) {
        top: 0;
      }

      span:nth-child(2) {
        top: 12px;
      }

      span:nth-child(3) {
        top: 24px;
      }
    }
    .menu-toggle.open span:nth-child(1) {
      top: 12px;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: rotate(135deg);
    }

    .menu-toggle.open span:nth-child(2) {
      opacity: 0;
      left: -60px;
    }

    .menu-toggle.open span:nth-child(3) {
      top: 12px;
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: rotate(-135deg);
    }
    .navbar-collapse {
      border-top:0;
    }
    #navbar {
      background:white;
      margin-top:20px;
      .navbar-nav {
        margin:7.5px -7.5px;
        background:white;
        .dropdown {
          a {
            &:after {
              content:"\f0d7";
              position:absolute;
              right:15px;
              top:8px;
              font-family: FontAwesome;
              color:lighten($green,25%);
            }
          }
          &.open {
            a {
              &:after {
                content:"\f0d8";
                position:absolute;
                right:15px;
                top:8px;
                font-family: FontAwesome;
                color:lighten($green,25%);
              }
            }
          }
        }
        .open {
          a,
          a:focus,
          a:hover {
            background:$green;
            color:white;
          }
          .dropdown-menu {
            background:lighten($green, 25%);
            padding:0;
            li {
              a {
                background:lighten($green, 25%);
                font-size:16px;
              }
              &.active {
                a {
                  background:$green;
                  color:white;
                }
              }
            }
          }
        }
        li {
          a {
            font-size:20px;
            font-weight:500;
            color:$green;
            text-transform:uppercase;
            text-align:center;
            padding:10px;
          }
        }
        .active {
          a {
            background:$green;
            color:white;
          }
        }
      }
    }
  }

  header {
    position:fixed;
    z-index:1000;
    background:white;
    height:90px;
    padding-bottom:0;
    top:35px;
    -webkit-box-shadow: 0 8px 6px -6px rgba(0,0,0,0.2);
    -moz-box-shadow: 0 8px 6px -6px rgba(0,0,0,0.2);
    box-shadow: 0 8px 6px -6px rgba(0,0,0,0.2);
    .brand {
      width:210px;
      margin-top:0;
      z-index:1001;
      position:relative;
    }
    .contact-info {
      display:none;
    }
  }

  #slider-container {
    margin-top:0;
    height:300px;
    #slider {
      min-height:300px;
      .owl-slide {
        height:300px !important;
        overflow:hidden;
        .container {
          padding-left:0;
          padding-right:0;
        }
        .block-slide {
          max-width:100%;
          margin-left:0;
          .title {
            width:100%;
            padding:0 30px;
            h1 {
              font-size:24px;
              line-height:32px;
              width:100%;
              padding:0 0 65px 0;
            }
          }
          &:before {
            background:rgba(196, 15, 123, 0.5);
            transform:rotate(0deg);
            top:0;
            left:0;
            width:100%;
          }
        }
      }
      .btn-slide {
        font-size:16px;
        padding:10px 15px;
        max-width:225px;
        right:0;
        &:after {
          font-size:16px;
          top:11px;
        }
      }
      .owl-nav {
        bottom:0;
        right:0;
        .owl-next,
        .owl-prev {
          opacity:0.8;
        }
      }
    }
  }

  #home-introduction {
    .home-intro-block {
      h1 {
        font-size:32px;
      }
      p {
        padding:0;
      }
    }
    &:after {
      left:0;
    }
  }

  #references {
    #slider-reference {
      .owl-slide {
        .col-half {
          width:100%;
        }
        .col-image {
          height:280px;
          .image-container {
            .image {
              height:280px !important;
            }
          }
        }
        .col-text {
          height:auto;
          .text-container {
            position:relative;
            top:0;
            left:auto;
            transform:translate(0,0);
          }
        }
      }
      .owl-dots {
        top:300px;
        left:0;
        right:0;
        margin:0 auto;
      }
    }
  }

  .page-heading {
    margin-top:0;
    min-height:230px;
    height:230px;
    h1 {
      font-size:30px;
    }
    #bg-image {
      min-height:230px !important;
      height: 230px !important;
      top:0 !important;
    }
  }

  .text-block {
    text-align:center;
    h2 {
      margin-top:0;
      padding-top:0;
    }
  }

  .btn-register {
    margin-top:0;
    span.icon-container {
      margin:0 auto;
      float:none;
    }
    span.btn-text {
      width:100%;
      text-align:center;
      font-size:18px;
      line-height:28px;
    }
  }

  .member {
    span.short {
      height:auto;
    }
  }

  .form-firstname,
  .form-insertion,
  .form-lastname,
  .form-half {
    width:100%;
    margin:0 0 8px 0;
  }
  .form-half + .form-half {
    width:100%;
  }
  .form-firstname,
  .form-half {
    padding-right:0;
  }
  .form-insertion {
    padding-left:0;
    padding-right:0;
  }
  .form-lastname,
  .form-half + .form-half {
    padding-left:0;
    margin:0;
  }

  .table-times {
    width:100%;
    margin:0;
    tr {
      td {
        width:50%;
        &:first-child {
          padding-right:25px;
          text-align:right;
        }
        &:last-child {
          text-align:left;
        }
      }
    }
  }

  #maps,
  #maps iframe {
    height:350px !important;
  }

  .text-block {
    h2 {
      &.kids-title {
        padding:60px 0 0 0 !important;
      }

    }
    h2 + p {
      img {
        width: 60px !important;
        height: 60px !important;
        top: -10px !important;
        left: 0 !important;
        right: 0 !important;
        margin: 0 auto !important;
      }
    }
  }
  #treatments {
    .text-block {
      padding:45px 15px;
    }
    .treatment {
      text-align:center;
      h2 {
        font-size:21px;
        padding:60px 0 0 0;
      }
    }
    .treatment-items {
      .img-icon {
        width:60px !important;
        height:60px !important;
        top:-10px;
        left:0;
        right:0;
        margin:0 auto;
      }
    }
  }

  footer {
    .footer-icons {
      li {
        a {
          width:60px;
          height:60px;
          img {
            max-width:45px;
          }
        }
      }
    }
  }
}