.navbar-top {
  width:100%;
  color:$grey-dark;
  position:relative;
  top:0;
  left:0;
  right:0;
  height:35px;
  font-weight:300;
  line-height:35px;
  vertical-align: middle;
  font-size:15px;
  z-index:1000;
  .contact {
    float:right;
    .address,
    .route {
      float:left;
    }
    .address {
      font-weight:400;
      font-size:15px;
      margin-right:6px;
    }
    .route {
      margin-top:-2px;
      a {
        background:$green;
        color:white;
        font-size:15px;
        border-radius:15px;
        padding:1px 8px 3px 20px;
        position:relative;
        &:before {
          content:"\f041";
          font-family: FontAwesome;
          position:absolute;
          left:6px;
          top:-6px;
          color:white;
        }
        &:hover,
        &:focus {
          background:$pink;
          text-decoration:none;
        }
      }
    }
  }
  &:before {
    content:"";
    width:60%;
    height:35px;
    background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(250,250,250,1) 100%);
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(250,250,250,1) 100%);
    background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(250,250,250,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#fafafa',GradientType=1 );
    position:absolute;
    right:0;
    top:0;
  }
}