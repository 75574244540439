.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  font-size: 18px;
  font-weight: 200;
  color: $grey;
  height: 56px;
  padding:8px 20px;
  border-radius: 6px;
  border-color: #ddd;
  box-shadow: none;
  font-family: $font-standard;
  &:focus,
  &:active {
    box-shadow: none;
    border-color: $green;
  }
}
.form-firstname {
  float:left;
  width:35%;
  padding-right:4px;
}
.form-insertion {
  float:left;
  width:15%;
  padding-right:4px;
  padding-left:4px;
}
.form-lastname {
  float:left;
  width:50%;
  padding-left:4px;
}
.form-half {
  float:left;
  width:50%;
  padding-right:4px;
}
.form-half + .form-half {
  float:left;
  width:50%;
  padding-left:4px;
  padding-right:0;
}
.form-small {
  float:left;
  width:30%;
  padding-right:4px;
}
.form-medium {
  float:left;
  width:70%;
  padding-left:4px;
}
.form-large {
  float:left;
  width:100%;
}

.form-group {
  margin: 0 0 8px 0;
  float: left;
  width: 100%;
}

textarea.form-control {
  resize: none;
  height: 250px;
  padding:15px 20px;
  border-radius:6px;
}

.alert {
  border-radius: 0;
  font-size: 18px;
  ul {
    margin: 0 0 0 19px;
    padding: 0;
    li {
      padding: 2px 0;
    }
  }
}

.has-error {
  position: relative;
  .form-control {
    border-color: $red;
  }
  &:after {
    content: "\f06a";
    font-family: FontAwesome;
    position: absolute;
    right: 15px;
    top: 14px;
    color: $red;
    font-size: 20px;
  }
}

.alert-danger {
  background: rgba(238, 115, 11, 0.1);
  color: $red;
  border-color: $red;
  border: 0;
}

#registerForm {
  .form-control::-webkit-input-placeholder {
    color: $grey;
    font-family: $font-standard;
    font-size: 18px;
    font-weight: 200;
  }
  .form-control:focus::-webkit-input-placeholder {
    color: $green;
    transition:color 0.25s ease;
    font-family: $font-standard;
    font-size: 18px;
    font-weight: 200;
  }
  .form-control::-moz-placeholder {
    color: $grey;
    font-family: $font-standard;
    font-size: 18px;
    font-weight: 200;
  }
  .form-control:focus::-moz-placeholder {
    color: $green;
    font-family: $font-standard;
    transition:color 0.25s ease;
    font-size: 18px;
    font-weight: 200;
  }
  .form-control:-ms-input-placeholder {
    color: $grey;
    font-family: $font-standard;
    font-size: 18px;
    font-weight: 200;
  }
  .form-control:focus:-ms-input-placeholder {
    color: $green;
    font-family: $font-standard;
    font-size: 18px;
    font-weight: 200;
    transition:color 0.25s ease;
  }
}
.form-content {
  h2 {
    margin:0 0 30px 0;
    padding:0;
    text-align:center;
  }
}
.form-radio {
  padding:15px 20px 0 0px;
  display:block;
  font-size:18px;
  font-weight:200;
  color:$grey;
  font-family: $font-standard;
}
.radio-inline {
  font-weight:200;
  color:$grey;
  font-family: $font-standard;
}
span.radio-title {
  display:block;
  font-size:18px;
  font-weight:300;
  float:left;
  opacity:1;
  padding:0 0 6px 0;
  margin:0 10px 0 0;
  color:$grey;
  font-family: $font-standard;
}
#registerForm {
  padding-bottom:60px;
  h2 {
    color:$green;
    font-weight:900;
    text-transform: uppercase;

  }
}

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background:$green !important;
}
